import React from 'react'
import './Portfolio.css'
import {Swiper, SwiperSlide} from 'swiper/react';
import Sidebar from '../../img/sidebar.png'
import Ecommerce from '../../img/ecommerce.png'
import HOC from '../../img/hoc.png'
import MusicApp from '../../img/musicapp.png'
import Reactt from '../../img/reactt.png'
import Javascript from '../../img/javascript.png'
import 'swiper/css'
import { themeContext } from '../../Context'
import { useContext } from 'react'

const Portfolio = () => {

     const mob = window.innerWidth <=540 ? true : false ;

     const theme = useContext(themeContext)
     const darkMode = theme.state.darkMode;
  return (
    <div className="portfolio" id='Portfolio'>
        <span style={darkMode ? {color: 'white'}: {}}>Recent Projects</span>
        <span>Portfolio</span>

       <Swiper 
       spaceBetween={30}
       slidesPerView= {mob? '1':'2'}
       grabCursor={true}
       className='portfolio-slider'
       >
        <SwiperSlide>
             <img src={Sidebar} alt="" />
        </SwiperSlide>
        <SwiperSlide>
             <img src={Ecommerce} alt="" />
        </SwiperSlide>
        <SwiperSlide>
             <img src={MusicApp} alt="" />
        </SwiperSlide>
        <SwiperSlide>
             <img src={HOC} alt="" />
        </SwiperSlide>
        <SwiperSlide>
             <img src={Reactt} alt="" />
        </SwiperSlide>
        <SwiperSlide>
             <img src={Javascript} alt="" />
        </SwiperSlide>
       </Swiper>

    </div>
  )
}

export default Portfolio
import React from 'react'
import './Footer.css'
import Wave from '../../img/wave.png'

const Footer = () => {
  return (
    <div className='footer'>
      <img src={Wave} alt="" style={{width:'100%'}}/>
      <div className="f-content">
        <span>satyabratadash915@gmail.com</span>
        <div className="f-icons">
        <a href='https://www.instagram.com/satya_dash99/' target='_blank' rel="noreferrer"><i class="fa-brands fa-square-instagram fa-3x" style={{color:'white'}}></i></a>
        <a href="https://www.facebook.com/login/?privacy_mutation_token=eyJ0eXBlIjowLCJjcmVhdGlvbl90aW1lIjoxNjY5MDI4MTExLCJjYWxsc2l0ZV9pZCI6MjY5NTQ4NDUzMDcyMDk1MX0%3D" target='_blank' rel="noreferrer"><i class="fa-brands fa-facebook fa-3x " style={{color:'white'}}></i> </a>  
        <a href="https://github.com/SatyA-Develop" target='_blank' rel="noreferrer"><i class="fa-brands fa-github fa-3x" style={{color:'white'}}></i></a>
        </div>
      </div>
    </div>
  )
}

export default Footer

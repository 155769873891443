import React from 'react'
import './Services.css'
import HeartEmogi from '../../img/heartemoji.png'
import Humble from '../../img/humble.png'
import Glass from '../../img/glasses.png'
import Card from '../Card/Card'
import Resume from './satres.pdf'
import { themeContext } from '../../Context'
import { useContext } from 'react'
import {motion} from 'framer-motion'

const Services = () => {

  const Lapi = window.innerWidth <= 1100 ? true : false ;

  const transition = {duration : 2 , type : 'spring'}

  const theme = useContext(themeContext)
  const darkMode = theme.state.darkMode;
  return (
    <div className='services' id='Services'>
        <div className="awesome">
            <span style={darkMode ? {color:'white'}: {}}>My Awesome</span>
            <span>services</span>
            <span>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Volupt as harum nesciunt 
                <br />
                eius a
                corporis reiciendis sit commodi, esse alias mollitia.</span>
                <a href={Resume} download='resume.pdf'><button className='button s-button'>Download CV</button></a>
                <div className="blur s-blur" style={{background:'#ABF1FF94'}}></div>
        </div>
        <div className="cards">
              <motion.div 
                initial={Lapi ? {left:'8rem', top: '9rem'}:{left:'24rem'}}
                whileInView={Lapi ? {left: '-6rem'}:{left:'14rem'}}
                transition={transition}
              style={{left:'14rem'}}>
                <Card
                emoji={HeartEmogi}
                heading={'Design'}
                details={'Figma, Sketch, Photoshop, Adobe, Adobe xd,GitHub,UI'}
                />
              </motion.div>
              <motion.div 
                initial={Lapi?{left:'-16rem'}:{left:'-12rem', top: '12rem'}}
                whileInView={Lapi? {left:'-20rem'}:{left:'-4rem'}}
                transition={transition}
              style={{left:'-4rem',top:'12rem'}}>
                <Card
                emoji={Glass}
                heading={'Development'}
                details={'Html, Css, JavaScript, React Js,Bootstarp,Css3,Html5'}
                />
              </motion.div>
              <motion.div 
                initial={Lapi?{left:'16rem',top:'25rem'}:{left:'25rem', top: '19rem'}}
                whileInView={Lapi?{left:'-3rem'}:{left:'12rem'}}
                transition={{duration: 3 , type: 'spring'}}
               style={{left:'12rem',top:'19rem'}}>
                <Card
                emoji={Humble}
                heading={'Blogger'}
                details={'lorem youtube is the platform where I am a blogger'}
                />
              </motion.div>
              <div className="blur s-blur2" style={{background:'var(--purple)'}}></div>
        </div>
      
    </div>
  )
}

export default Services

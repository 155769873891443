import React from 'react'
import './Uparrow.css'
import { Link } from 'react-scroll'

const Uparrow = () => {
  return (
    <div className='uparrow' >
      <Link spy={true} to='navbar' smooth={true}  ><i class="fa-solid fa-angle-up fa-2x"></i></Link>
    </div>
  )
}

export default Uparrow

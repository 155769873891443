import React, { useState } from 'react'
import Toggle from '../Toggle/Toggle'
import './Navbar.css'
import { Link } from 'react-scroll'

const Navbar = () => {

    const mobile = window.innerWidth <= 768 ? true : false ;
    const [menuOpened,setMenuOpened] = useState(false);
  return (
    <div className="n-wrapper" id='navbar' >
        <div className="n-left">
            <div className="n-name">Satyabrata</div>
             <Toggle/>
        </div>
        <div className="n-right">
            {menuOpened===false && mobile === true ? (
                <div className='menu-button' onClick={()=>setMenuOpened(true)}>
                    <div className="hamberg-menu">
                        <div className="hamberg-bar"></div>
                        <div className="hamberg-bar"></div>
                        <div className="hamberg-bar"></div>
                    </div>
                </div>
            ):(
                
               <div className="n-list">
                <div className='cross-icon'
                 onClick={()=>setMenuOpened(false)}><i class="fa fa-window-close" aria-hidden="true"></i></div>
                <ul>
                    <Link spy={true} to='intro' onClick={()=>setMenuOpened(false)} activeClass="activeClass" smooth={true}  ><li>Home</li></Link>
                    <Link spy={true} to='Services' onClick={()=>setMenuOpened(false)} smooth={true}  ><li>Services</li></Link>
                    <Link spy={true} to='Experience' onClick={()=>setMenuOpened(false)} smooth={true}  ><li>Experience</li></Link>
                    <Link spy={true} to='Portfolio' onClick={()=>setMenuOpened(false)} smooth={true}  ><li>Portfolio</li></Link>
                    <Link spy={true} to='Testimonials' onClick={()=>setMenuOpened(false)} smooth={true}  ><li>Testimonials</li></Link>
                </ul>
            </div>
            )
            
            }
            
            <Link  to='contact' spy={true} smooth={true}><button className="button n-button">
                Contact
            </button></Link>
        </div>
    </div>
  )
}

export default Navbar

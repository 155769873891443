import React from 'react'
import './Testimonials.css'
import { Swiper,SwiperSlide } from 'swiper/react'
import 'swiper/css'
import {clients} from '../../ClientsData'
import { Pagination } from 'swiper'
import 'swiper/css/pagination'

const Testimonials = () => {
  return (
    <div className='t-wrapper' id='Testimonials'>
      <div className="t-heading">
        <span>Clients always get </span>
        <span>Exceptional Work </span>
        <span>from me...</span>
        <div className="blur t-blur1" style={{background:'var(--purple)'}}></div>
        <div className="blur t-blur2" style={{background:'skyblue'}}></div>
      </div>
     <Swiper
     
     slidesPerView={1}
     modules={[Pagination]}
     pagination={{clickable:true}}
     >
       {clients.map((clients,index)=>{
        return <SwiperSlide key={index}>
            <div className="testimonials">
            <img src={clients.img} alt="" />
            <span>{clients.review}</span>
            </div>
        </SwiperSlide>
       })}
     </Swiper>

    </div>
  )
}

export default Testimonials

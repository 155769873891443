import React from "react";
import "./Intro.css";
import Typical from "react-typical";
import GitHub from "../../img/github.png";
import LinkedIn from "../../img/linkedin.png";
import Instagram from "../../img/instagram.png";
import Boy from "../../img/boy.png";
import Vector1 from "../../img/Vector1.png";
import Vector2 from "../../img/Vector2.png";
import Crown from "../../img/crown.png";
import glassesemoji from "../../img/glassesimoji.png";
import thumpup from "../../img/thumbup.png";
import Floatingdiv from "../Floatingdiv/Floatingdiv";
import { themeContext } from '../../Context'
import { useContext } from 'react'
import {motion} from 'framer-motion'
import Uparrow from "../Uparrow/Uparrow";
import { Link } from "react-scroll";


const Intro = () => {


  const Lapi = window.innerWidth<=912 ? true : false ;
  const Mob = window.innerWidth<=280 ? true : false ;

const transition = {duration : 2 , type : 'spring'}

  const theme = useContext(themeContext)
  const darkMode = theme.state.darkMode;

  return (
    <div className="intro" id="intro">
      <div className="i-left">
        <div className="i-name">
          <span style={darkMode ? {color:'white'}: {}} >Hy! I Am </span>
          <span>Satyabrata Dash</span>
          <span>
            Frontend Developer with enthusiastic persionality.Believe on quality
            work.Work life at high balance.{" "}
          </span>
        </div>

        <div className="i-skill">
          <span style={darkMode ? {color:'white'}: {}}>I am a</span>
          <Typical 
            loop={Infinity}
            wrapper="b"
            steps={[
              "React Developer📲",
              1000,
              "Front-end Developer💻",
              1000,
              "Blogger📸",
              1000,
              "Writter📝",
              1000,
            ]}
          />
        </div>
        
          <Link  to='contact' spy={true} smooth={true}><button className=" button i-button" >Hire me</button></Link>
        <div className="i-icons">
          <a
            href="https://github.com/SatyA-Develop"
            target="_blank"
            rel="noreferrer"
          >
            <img src={GitHub} alt="" />
          </a>
          <a
            href="https://www.linkedin.com/feed/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={LinkedIn} alt="" />
          </a>
          <a
            href="https://www.instagram.com/satya_dash99/?next=%2F"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Instagram} alt="" />
          </a>
        </div>
      </div>
      <div className="i-right">
        <img src={Vector1} alt="" />
        <img src={Vector2} alt="" />
        <img src={Boy} alt="" />
        <motion.div
        initial={Lapi?{top:'12%',left:'-24%'}:{top: '-4%' , left:'74%'}}
        whileInView={Lapi?{left:'30%'}:{left:'60%'}}
        transition={transition}
        className='floating-div'
        >
          <Floatingdiv image={Crown} txt1="Web" txt2="Developer" />
        </motion.div>
        <motion.div
        initial={{top: '18.3rem' , left:'18rem'}}
        whileInView={{left:'2rem'}}
        transition={transition}
        className='floating-div'
        >
          <Floatingdiv image={thumpup} txt1="Web" txt2="Designer" />
        </motion.div>
        <motion.img 
        initial={Mob ? {top:'2rem'}:{left:'-36%'}}
        whileInView={Mob?{top:'2rem'}:{left:'-24%'}}
        transition={transition}
        src={glassesemoji} alt="" />
        <div style={{
    background: 'rgb(238, 210, 255)'}} className="blur"></div>
    <div style={{background:'#c1f5ff',top:'17rem',left:'-9rem',width:'21rem',height:'11rem'}} className="blur"></div>
      </div>
      <Uparrow/>
    </div>
  );
};

export default Intro;

import profilepic1 from '../src/img/profile1.jpg'
import profilepic2 from '../src/img/profile2.jpg'
import profilepic3 from '../src/img/profile3.jpg'
import profilepic4 from '../src/img/profile4.jpg'
import profilepic5 from '../src/img/profile5.jpg'
import profilepic6 from '../src/img/profile6.jpg'
export const clients =[
{
    img: profilepic1 ,
    review : "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaque commodi numquam illo voluptatem explicabo, ratione sit quaerat quod fugit autem aut nobis excepturi reiciendis. Repudiandae odio veritatis illum laborum minima."
},
{
    img: profilepic2 ,
    review : "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaque commodi numquam illo voluptatem explicabo, ratione sit quaerat quod fugit autem aut nobis excepturi reiciendis. Repudiandae odio veritatis illum laborum minima."
},
{
    img: profilepic3 ,
    review : "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaque commodi numquam illo voluptatem explicabo, ratione sit quaerat quod fugit autem aut nobis excepturi reiciendis. Repudiandae odio veritatis illum laborum minima."
},
{
    img: profilepic4 ,
    review : "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaque commodi numquam illo voluptatem explicabo, ratione sit quaerat quod fugit autem aut nobis excepturi reiciendis. Repudiandae odio veritatis illum laborum minima."
},
{
    img: profilepic5 ,
    review : "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaque commodi numquam illo voluptatem explicabo, ratione sit quaerat quod fugit autem aut nobis excepturi reiciendis. Repudiandae odio veritatis illum laborum minima."
},
{
    img: profilepic6 ,
    review : "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eaque commodi numquam illo voluptatem explicabo, ratione sit quaerat quod fugit autem aut nobis excepturi reiciendis. Repudiandae odio veritatis illum laborum minima."
},
]

import React, { useState } from 'react'
import './Contact.css'
import { useRef } from 'react';
import emailjs from '@emailjs/browser'
import { themeContext } from '../../Context'
import { useContext } from 'react'



const Contact = () => {


  const theme = useContext(themeContext)
  const darkMode = theme.state.darkMode;
  const [done,setDone] = useState(false);
    const form = useRef(null);
     

    const sendEmail = (e) => {
      e.preventDefault();
      e.target.reset();
      emailjs.sendForm('service_nmfxssn', 'template_a0yh1c6', form.current, 'wbnmFu01bvf1Jkr5Z')
        .then((result) => {
            console.log(result.text);
            setDone(true);
        }, (error) => {
            console.log(error.text);
        });
    };

  return (
    <div className='contact-form' id='contact'>
      <div className="c-left">
        <div className="awesome">
            <span style={darkMode ? {color:'white'}: {}}>Get in touch</span>
            <span>Contact me</span>
            <div className="blur s-blur1" style={{background:'#ABF1FF94'}}></div>
        </div>
      </div>

      <div className="c-right">
        <form ref={form} onSubmit={sendEmail}>
        <input type="text" name='user_name' className='user' placeholder='Name' required/>
        <input type="email" name='user_email' className='user' placeholder='Email' required/>
        <textarea name="message" className="user"placeholder='Message for me'></textarea>
        <input type="submit" value='send' className='button submit-btn' required />
        <span>{done && "Thanks for contacting me"}</span>
        <div className='blur c-blur-1' style={{background:'var(--purple'}}></div>
        </form>
      </div>
    </div>
  )
}

export default Contact
